.input_class {
  height: 62px !important;
  font-family: "Avenir Next";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.004em;
  color: #000000;
  width: 100%;
  border-radius: 16px !important;
  border-color: #BDBFC7 !important;
  background-color: white !important;
  width: 100% !important;
}

.input_class:focus {
  border-color: #f76666 !important;
}

.input_class:focus + .button_class {
  border-color: #f76666 !important;
}

.input_class:not(:placeholder-shown) {
  background-color: white !important;
}

.input_class:not(:placeholder-shown) + .button_class {
  background-color: white !important;
}

.inputClass::placeholder {
  color: #9ea0a8;
}

.container_class {
  width: 100%;
}

.button_class {
  background-color: white !important;
  border-color: #BDBFC7 !important;
  border-radius: 16px 0 0 16px !important;
  border-right: none !important;
}

.react-tel-input .selected-flag {
  border-radius: 16px 0 0 16px !important;
}

.react-tel-input .selected-flag:hover {
  background-color: #f6f6f8 !important;
}
